<template>
    <div class="page1">
        <div class="mb20">
            <span class="pr20">摊位号<span class="pl10">{{form.boothCode}}</span></span>
            <span class="pr20">费用名称<span class="pl10">{{form.takeReturnProjectName}}</span></span>
        </div>
        <el-form inline label-width="100px" label-position="left" :model="form" v-if="form.boothCode">
            <el-row>
                <el-col :span="6">
                    <el-form-item label="区域">

                        <div >{{ form.areaName || '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="位置">

                        <div >{{ form.placeName|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="摊位">

                        <div >{{ form.boothCode|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="身份证号码">

                        <div >{{ form.idCard|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="合同开始日期">
                        <div >{{ form.inDate|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="负责人名称">
                        <div >{{ form.signPerson|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="联系电话">
                        <div >{{ form.phone|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="摊位面积">
                        <div >{{ form.areaName|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="合同类别">
                        <div >{{ form.contractTypeCn|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="合同到期日期">
                        <div >{{ form.expireDate|| '-'}}</div>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>

        <div v-if="form.feeType==1">
            <div class="title">
                {{form.takeReturnProjectName}}
            </div>
            <div class="mb20">
                <span class="pr20">电价<span class="pl10">{{electricPrice}}</span></span>
                <span class="pr20">服务费单价<span class="pl10">{{electricUnitPrice}}</span></span>
                <span class="pr20">合计<span class="pl10">{{sum}}</span></span>
            </div>
            <el-form inline label-width="140px" label-position="left">
                <el-row>
                    <!--          <el-col :span="8">-->
                    <!--            <el-form-item label="电价">-->
                    <!--              {{electricPrice}}-->
                    <!--            </el-form-item>-->
                    <!--          </el-col>-->
                    <!--          <el-col :span="8">-->
                    <!--            <el-form-item label="服务费单价">-->
                    <!--              {{electricUnitPrice}}-->
                    <!--            </el-form-item>-->
                    <!--          </el-col>-->
                    <!--          <el-col :span="8">-->
                    <!--            <el-form-item label="合计">-->
                    <!--              {{sum}}-->
                    <!--            </el-form-item>-->
                    <!--          </el-col>-->
                    <el-col :span="8" :sm="10">
                        <el-form-item label="购电金额">
                            <el-input class="number" type="number" v-model.number="form.totalAmount" @input="calcFee"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="费用开始时间">
                            <el-date-picker
                                    v-model="form.feeBeginDate"
                                    type="date"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="费用结束时间">
                            <el-date-picker
                                    v-model="form.feeEndDate"
                                    type="date"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="购电度数">
                            <el-input class="number" type="number" v-model="form.degrees" @input="calcAmount"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="是否需要财务确认">
                            <el-radio-group v-model="form.isConfirm">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="备注">
                            <el-input type="textarea" v-model="form.remark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>

        <div v-if="form.feeType==4">
            <div class="title">
                {{form.takeReturnProjectName}}
            </div>
            <el-form inline label-width="140px" label-position="left">
                <el-row>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="金额">
                            <el-input v-model="form.totalAmount"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="费用开始时间">
                            <el-date-picker
                                    v-model="form.feeBeginDate"
                                    type="date"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="费用结束时间">
                            <el-date-picker
                                    v-model="form.feeEndDate"
                                    type="date"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="是否需要财务确认">
                            <el-radio-group v-model="form.isConfirm">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="备注">
                            <el-input type="textarea" v-model="form.remark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="mt20">
            <el-button type="primary" size="small" @click="saveData" :disabled="saveDisabled">
                确认录入
            </el-button>
            <el-button size="small" @click="goBack()">
                返回
            </el-button>
        </div>

    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";
  import * as math from 'mathjs'

  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        boothId: '',
        feeId: '',
        options1: [],//摊位号下拉
        options2: [],//费用录入名称
        title: '', //费用名称
        elePriceList: [], //电费信息
        type: '',
        allAmount: '',
        form: { //费用录入传值
          areaId: '',
          areaName: '',
          boothCode: '',
          contractId: '',
          contractType: '',
          degrees: '',
          feeBeginDate: '',
          feeEndDate: '',
          feeType: 4,//3:电卡充值 4:其他收费项 5:抄电表费
          isConfirm: 1,
          orderId: '',
          placeId: '',
          placeName: '',
          remark: '',
          takeReturnProjectId: '',
          takeReturnProjectName: '',
          tenantId: '',
          tenantName: '',
          totalAmount: ''
        },
        orderId: '',

      }
    },
    watch: {},
    created() {
      this.orderId = this.$route.query.id
      // this.cmboothinformationLabel()
      // this.gettakereturnprojectLabel()
      this.expenseEntryDetail()
      this.getData()
    },
    components: {ETable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {
      changeBoothId(val) {
        this.boothId = val
        this.expenseEntryDetail()
      },
      changeFeeId(val) {
        this.feeId = val

      },
      //费用录入详情
      async expenseEntryDetail() {
        let res = await Http.expenseEntryDetail({id: this.orderId})
        if (res.code == 200) {
          this.form = res.data
          this.form.degrees = res.data.electricNum
          this.form.isConfirm = Number(res.data.isConfirm)
        }
      },
      //由购电金额得到购电度数
      calcFee(totalAmount) {
        let tmpDegrees = ''
        if (totalAmount) {
          tmpDegrees = math.round(math.evaluate(`${totalAmount} / ${this.electricPrice}`), 2)
        }
        this.form.degrees = tmpDegrees
      },
      //由购电度数得到购电金额
      calcAmount(degrees) {
        let tmpAmount = ''
        if (degrees) {
          tmpAmount = math.round(math.evaluate(`${degrees} * ${this.electricPrice}`), 2)
        }
        this.form.totalAmount = tmpAmount
      },
      saveData() {
        this.expenseEntryUpdatee()

      },
      async expenseEntryUpdatee() {
        this.setDisabled(true)
        if (this.form.contractTypeCn == '长租') {
          this.form.contractType = 1
        } else if (this.form.contractTypeCn == '短租') {
          this.form.contractType = 2
        } else {
          this.form.contractType = 3
        }
        let res = await Http.expenseEntryUpdatee(this.form)
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.setDisabled(false)
          this.goBack()
        }
      },
      //摊位信息-摊位号下拉
      // async cmboothinformationLabel() {
      //   let res = await Http.cmboothinformationLabel({isRent: 1})
      //   if (res.code == 200) {
      //     this.options1 = res.data
      //   }
      // },
      // //费用录入收费项下拉列表
      // async gettakereturnprojectLabel() {
      //   let res = await Http.gettakereturnprojectLabel()
      //   if (res.code == 200) {
      //     this.options2 = res.data
      //   }
      // },
      // 获取电卡电价列表
      async getData() {
        let res = await Http.getElePriceList()
        if (res.code == 200) {
          this.elePriceList = res.data
        }
      }
    },
    computed: {
      sum() {
        return math.chain(this.electricPrice).add(this.electricUnitPrice).done()
      },
      electricPrice() {
        return (this.elePriceList[0] && this.elePriceList[0].electricPrice) || 0
      },
      electricUnitPrice() {
        return (this.elePriceList[0] && this.elePriceList[0].electricUnitPrice) || 0
      }
    }
  }
</script>

<style lang="scss" scoped>
    .title {
        font-weight: bold;
        padding-left: 10px;
        border-left: 2px solid #4d68ff;
        margin-bottom: 20px;
    }

    ::v-deep .number {
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        input[type="number"] {
            -moz-appearance: textfield;
        }
    }
</style>
